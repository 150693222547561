import type React from 'react';
import ReactDOMLegacy from 'react-dom';

/**
 * This file is a compatibility layer for migration from React 17 to React 18.
 */

export function createRoot(rootElement: HTMLElement) {
  return {
    render: (element: React.ReactElement) => {
      return ReactDOMLegacy.render(element, rootElement);
    },
    unmount: () => {
      ReactDOMLegacy.unmountComponentAtNode(rootElement);
    },
  };
}
