import React, {
  type ComponentPropsWithRef,
  type ElementType,
  type HTMLAttributes,
  type MouseEventHandler,
  type RefObject,
  useLayoutEffect,
  useState,
} from 'react';
import { mergeProps as reactAriaMergeProps } from 'react-aria';
import { format } from 'date-fns';
import type { RenderProp } from './types';

/* @__PURE__ */
export function assertValidDOMAttributes(props: HTMLAttributes<unknown>) {
  if (process.env.IS_PRODUCTION) {
    // Do not assert in production.
    return;
  }

  const illegalProps = Object.keys(props).filter((prop) => {
    const isAriaAttribute = prop.startsWith('aria-');
    const isDataAttribute = prop.startsWith('data-');
    const isOnAttribute = /^on[A-Z]/.test(prop) && !prop.startsWith('onPress');
    const isOtherAttribute = [
      'id',
      'className',
      'style',
      'tabIndex',
      'rel',
      'href',
      'target',
      'type',
      'role',
      'disabled',
      'form',
    ].includes(prop);

    if (!isAriaAttribute && !isDataAttribute && !isOnAttribute && !isOtherAttribute) {
      return true;
    }

    return false;
  });

  if (illegalProps.length > 0) {
    throw new Error(
      `${illegalProps} is/are not valid DOM attributes. Omit the props before spreading them on the leaf nodes. If you think this error is incorrect then please contact the owners of the design system.`,
    );
  }
}

/**
 * Returns the tag name by parsing an element ref.
 * @example
 * function Component(props) {
 *   const ref = React.useRef();
 *   const tagName = useTagName(ref, "button"); // div
 *   return <div ref={ref} {...props} />;
 * }
 */
export function useElementType(ref: RefObject<HTMLElement> | null, defaultTag: string) {
  const [elementType, setElementType] = useState(defaultTag);

  useLayoutEffect(() => {
    const element = ref?.current;
    if (element) {
      setElementType(element.tagName.toLowerCase());
    }
  }, [ref]);

  return elementType as ElementType;
}

export function renderElement(
  props: ComponentPropsWithRef<ElementType> & { render: RenderProp },
  children: React.ReactNode,
  ref: React.RefCallback<unknown>,
) {
  const { render, ...rest } = props;

  const element = typeof render === 'function' ? render() : render;
  if (!React.isValidElement(render)) {
    throw new Error('Invalid render prop.');
  }

  return React.cloneElement(
    element,
    {
      ref,
      ...reactAriaMergeProps(element.props, rest),
    },
    children,
  );
}

export const handlePreventDefaultMouseEvent: MouseEventHandler = (e) => {
  e.preventDefault();
};

type ComponentMap = Record<string, React.ReactNode>;

/**
 * Returns an object whose keys are the same as componentMap. The value for each of those keys
 * is the child of the type represented by the value in componentMap. Throws an error if there
 * are children that are not requested in the componentMap.  Throws an error if there are
 * duplicate children for a single key within the component map.
 *
 * You can think of the keys as slots as this is a common use case: Card.Header goes in the
 * header slot and Card.Footer in the footer slot.
 *
 * @param children The children of a component
 * @param componentMap The requested mapping
 * @returns The children according to slot
 */
export function extractChildren<T extends ComponentMap>(
  children: React.ReactNode,
  componentMap: T,
): Partial<{ [K in keyof T]: React.ReactElement }> {
  const mappedChildren: Partial<{ [K in keyof T]: React.ReactElement }> = {};

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    const componentMapKeys: (keyof T)[] = Object.keys(componentMap);

    const childComponentName = getComponentName(child);

    const matchedKey = componentMapKeys.find((key) => {
      // Disabling name match because it is not a reliable way to detect correct components.
      // The identifiers get minified on production.

      // const mappedTypeName = getComponentName(componentMap[key]);
      // const nameMatches =
      //   childComponentName && mappedTypeName && childComponentName === mappedTypeName;

      // if (nameMatches) {
      //   return true;
      // }

      const typeMatches = child.type === componentMap[key];

      if (typeMatches) {
        return true;
      }

      return false;
    });

    if (!matchedKey) {
      throw new Error(`Unknown child of type ${childComponentName}`);
    }

    if (mappedChildren[matchedKey]) {
      throw new Error(`Duplicate child for slot "${String(matchedKey)}"`);
    }

    mappedChildren[matchedKey] = child;
  });

  return mappedChildren;
}

type BifurcateChildrenReturn<T extends ComponentMap> = {
  matched: { [K in keyof T]?: React.ReactElement };
  unmatched: React.ReactNode[];
};

/**
 * Returns an object with two keys:
 * - The "matched" key has an object whose keys are the same as componentMap. The value for each of those keys
 * is the child of the type represented by the value in componentMap. This is the same as the return value for
 * extractChildren
 * - The "unmatched" key has an array of all the other children not requested by the component map
 *
 * Throws an error if there are duplicate children for a single key within the component map. Unlike
 * extractChildren, unknown child types are allowed.
 *
 * @param children The children of a component
 * @param componentMap The requested mapping
 * @returns The object containing matched and unmatched children
 */
export function bifurcateChildren<T extends ComponentMap>(
  children: React.ReactNode,
  componentMap: T,
): BifurcateChildrenReturn<T> {
  const matched: BifurcateChildrenReturn<T>['matched'] = {};
  const unmatched: BifurcateChildrenReturn<T>['unmatched'] = [];
  const componentMapKeys: (keyof T)[] = Object.keys(componentMap);

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      unmatched.push(child);
      return;
    }

    const mappedType = componentMapKeys.find((key) => child.type === componentMap[key]);

    if (mappedType) {
      if (!matched[mappedType]) {
        matched[mappedType] = child;
      } else {
        throw new Error(`Duplicate child for slot "${String(mappedType)}"`);
      }
    } else {
      unmatched.push(child);
    }
  });

  return { matched, unmatched };
}

/**
 * Returns the name of the component type
 * @param component
 * @returns
 */
export function getComponentName(component: React.ReactNode) {
  const defaultReturnValue = '';

  if (!component || (typeof component !== 'function' && typeof component !== 'object')) {
    return defaultReturnValue;
  }

  if (
    'displayName' in component &&
    typeof component.displayName === 'string' &&
    component.displayName
  ) {
    return component.displayName;
  }

  if ('name' in component && typeof component.name === 'string' && component.name) {
    return component.name;
  }

  if ('type' in component) {
    if (typeof component.type === 'string' && component.type) {
      return component.type;
    }

    if (
      component.type &&
      (typeof component.type === 'object' || typeof component.type === 'function') &&
      'displayName' in component.type &&
      typeof component.type.displayName === 'string' &&
      component.type.displayName
    ) {
      return component.type.displayName;
    }

    if (
      component.type &&
      (typeof component.type === 'object' || typeof component.type === 'function') &&
      'name' in component.type &&
      typeof component.type.name === 'string' &&
      component.type.name
    ) {
      return component.type.name;
    }

    if (
      component.type &&
      (typeof component.type === 'object' || typeof component.type === 'function') &&
      'render' in component.type &&
      typeof component.type.render === 'function' &&
      'name' in component.type.render &&
      typeof component.type.render.name === 'string' &&
      component.type.render.name
    ) {
      return component.type.render.name;
    }
  }

  if (
    'render' in component &&
    typeof component.render === 'function' &&
    'name' in component.render &&
    typeof component.render.name === 'string' &&
    component.render.name
  ) {
    return component.render.name;
  }

  return defaultReturnValue;
}

/*
Ensures the children are all of the accepted types or else will throw an error if desired
*/
export function assertValidChildren(
  children: React.ReactNode,
  acceptedChildren: string[],
  shouldThrow: boolean,
  parentName: string,
) {
  return React.Children.map(children, (child) => {
    const childTypeName = getComponentName(child);

    if (React.isValidElement(child)) {
      if (acceptedChildren.includes(childTypeName)) {
        return React.cloneElement(child, { ...child.props });
      }
    }

    if (shouldThrow) {
      throw new Error(
        `${parentName} cannot have child elements other than ${acceptedChildren.join(', ')}.`,
      );
    }

    return child;
  });
}

export function removeUndefinedValues<T>(object: Record<string, T>) {
  for (const key in object) {
    if (object[key] === undefined) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-dynamic-delete
      delete object[key];
    }
  }

  return object;
}

/**
 * Extracts props from children
 * @example const props = extractPropsFromChildren(children);
 * @param children The children of a component
 * @param name The name of the component to extract props from
 */
export function extractPropsFromChildren<T extends React.ReactElement['props']>(
  children: React.ReactNode,
  name?: string,
): T[] {
  return (
    React.Children.map(children, (child) => {
      if (React.isValidElement(child) && (!name || getComponentName(child) === name)) {
        return child.props;
      }
    }) ?? []
  );
}

/**
 * Returns the children without any React.Fragment elements (it does include it's children).
 */
export function getChildrenWithoutFragments(children: React.ReactNode): React.ReactElement[] {
  let result: React.ReactElement[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === React.Fragment) {
        result = result.concat(getChildrenWithoutFragments(child.props.children));
      } else {
        result.push(child);
      }
    }
  });

  return result;
}

/**
 * Returns an array of children of given type.
 *
 * Note: The search is recursive, so make sure the needed elements are shallow to avoid searching too deeply on the DOM
 * tree.
 * @param children
 * @param component
 */
export function extractChildrenOfType<T>(
  children: React.ReactNode,
  component: T,
): React.ReactElement[] {
  const foundChildren: React.ReactElement[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === component) {
        foundChildren.push(child);
      } else if (child.props.children) {
        foundChildren.push(...extractChildrenOfType(child.props.children, component));
      }
    }
  });

  return foundChildren;
}

export function propsHaveAriaLabel(props?: Record<string, unknown>) {
  return Boolean(props?.['aria-label'] || props?.['aria-labelledby']);
}

export const generateUniqueId = () => (Math.random() + 1).toString(36).substring(7);

/**
 * Merges two sets of props.
 *
 * Source: React Ariakit
 */
export function mergeProps<T extends HTMLAttributes<unknown>>(base: T, overrides: T) {
  const props = { ...base };

  for (const key in overrides) {
    if (!Object.prototype.hasOwnProperty.call(overrides, key)) {
      continue;
    }

    if (key === 'className') {
      const prop = 'className';
      props[prop] = base[prop] ? `${base[prop]} ${overrides[prop]}` : overrides[prop];
      continue;
    }

    if (key === 'style') {
      const prop = 'style';
      props[prop] = base[prop] ? { ...base[prop], ...overrides[prop] } : overrides[prop];
      continue;
    }

    const overrideValue = overrides[key];

    if (typeof overrideValue === 'function' && key.startsWith('on')) {
      const baseValue = base[key];
      if (typeof baseValue === 'function') {
        type EventKey = Extract<keyof HTMLAttributes<unknown>, `on${string}`>;
        props[key as EventKey] = (...args) => {
          overrideValue(...args);
          baseValue(...args);
        };
        continue;
      }
    }

    props[key] = overrideValue;
  }

  return props;
}

/** Checks if the value is a Date object */
export function isDate(value: unknown): value is Date {
  return value instanceof Date && !Number.isNaN(Number(value));
}

/**
 * Returns the first focusable child of a dialog.
 * Following are the note from https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/#keyboardinteraction
 * The list of selectors comes from https://twitter.com/heydonworks/status/880773131287359488
 * Additionally, we're excluding the close and info buttons because they have tooltips which creates a weird experience.
 */
export function getDialogFirstFocusableChild(element: HTMLElement | null): HTMLElement | null {
  if (!element) {
    return null;
  }

  const selectors = `
    button:not([aria-label="Close"]):not([aria-label="Close drawer"]):not([aria-label="Info"]):not([aria-label="Navigate back"]),
    [href],
    input,
    select,
    textarea,
    [tabindex]:not([tabindex="-1"])
  `;

  return element.querySelector(selectors);
}

/**
 * Returns the portal element for a given element.
 */
export function getPortalElement(
  element: HTMLElement,
  isModalOrDrawerElement?: boolean,
): HTMLElement {
  // Skip this if given element is a Modal or Drawer element to prevent it portal to a Dialog element.
  if (!isModalOrDrawerElement) {
    const closestDialogElement = element.closest('[role="dialog"]');
    if (closestDialogElement) {
      return closestDialogElement as HTMLElement;
    }
  }

  const rootElement = element.closest('[data-ds2-root]');
  if (rootElement) {
    return rootElement as HTMLElement;
  }

  const newElement = element.ownerDocument.createElement('div');
  // This is required because DS2.0 Modal is being used along with DS 1.0 Modal which has z-index: 20000
  if (isModalOrDrawerElement && document.querySelector('[data-ds-legacy-modal]')) {
    newElement.style.zIndex = '20001';
    newElement.style.position = 'relative';
  }
  return newElement;
}

/**
 * Handle the step change for input type number
 * @param input - The input element to adjust. If null, the function does nothing.
 * @param direction - The direction to adjust the input value. 'up' increases the value, 'down' decreases it.
 */
export const handleStepChange = (input: HTMLInputElement | null, direction: 'up' | 'down') => {
  if (!input) {
    return;
  }

  const originalStep = input.step;
  const isAnyStep = originalStep.toLowerCase() === 'any';

  if (isAnyStep) {
    // eslint-disable-next-line no-param-reassign
    input.step = '1';
  }

  if (direction === 'up') {
    input.stepUp();
  } else {
    input.stepDown();
  }

  if (isAnyStep) {
    // eslint-disable-next-line no-param-reassign
    input.step = originalStep;
  }

  // Dispatch an input event to notify changes
  const event = new Event('input', { bubbles: true });
  input.dispatchEvent(event);
};

export function mergeRefs<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return (element: T) => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }
      if (typeof ref === 'function') {
        ref(element);
      } else {
        const mutableRef = ref as React.MutableRefObject<T>;
        mutableRef.current = element;
      }
    });
  };
}

export function formatDatetime(date: Date) {
  return format(date, 'MMM d, yyyy h:mm a');
}

export const copyToClipboard = (str: string) => {
  const textField = document.createElement('textarea');
  textField.value = str;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};
